<template>
    <div class="home">
    <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.search_data" style="width:400px" placeholder="一级分类名称"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div class="left">
        <el-button  @click="menuChangeStatus(1)" type="success" style="font-size: 16px;font-weight: bold;" plain>启用</el-button>
        <el-button  @click="menuChangeStatus(0)" type="warning" style="font-size: 16px;font-weight: bold;" plain>禁用</el-button>
        <el-button  @click="menuChangeStatus(-1)" type="danger" style="font-size: 16px;font-weight: bold;" plain>删除</el-button>
        <el-button  @click="add()" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
      </div>
      <div class="pt20"></div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="id"
          width="200"
          label="Id">
        </el-table-column>
        <el-table-column
          prop="name"
          width="200"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间">
          <template  slot-scope="scope">
            {{scope.row.create_time | formatDate}}
         </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态">
          <template  slot-scope="scope">
            {{scope.row.status == 1?'启用':'禁用'}}
          </template>
        </el-table-column>
        <el-table-column
        width="100"
          prop="_child"
          label="展开"
          type="expand">
          <template v-if="scope.row._child"  slot-scope="scope">
            <el-table v-loading="loading"
            :data="scope.row._child"
            style="width: 100%"
            :header-cell-style="{background:'#fdf6ec'}"
            @selection-change="handleSelectionChange"
            >
            <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="id"
          width="200"
          label="Id">
        </el-table-column>
        <el-table-column
          prop="name"
          width="200"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间">
          <template  slot-scope="scope">
            {{scope.row.create_time | formatDate}}
         </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态">
          <template  slot-scope="scope">
            {{scope.row.status == 1?'启用':'禁用'}}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="_child"
          label="子类">
          <template  slot-scope="scope">
            {{scope.row.status ?'子类':'子类'}}
         </template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template  slot-scope="scope">
            <el-button @click='bianji(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
            <el-button @click='dd(scope.row.id)' size="mini" type="info" plain>删除</el-button>
         </template>
        </el-table-column>
          </el-table>
          </template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template  slot-scope="scope">
            <el-button @click='bianji(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
            <el-button @click='dd(scope.row.id)' size="mini" type="info" plain>删除</el-button>
         </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <el-dialog
        title="编辑银行"
        :visible.sync="eddbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="registerdata" :model="registerdata" label-width="120px">
            <el-form-item label="名称" prop="name">
              <el-input v-model.trim="registerdata.name" placeholder="请输入问题分类名称（系统后台显示问题分类名称）" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="排序值" prop="sort">
              <el-input v-model.number="registerdata.sort" placeholder="请输入排序值（默认为0）" clearable required></el-input>
            </el-form-item>
            <el-form-item label="父级" prop="pid">
              <el-select style="width:200px;"  v-model="registerdata.pid" placeholder="请选择一级分类">
                <el-option
                      label="一级分类"
                      :value="0">
                    </el-option>
                 <el-option
                      v-for="(ot,i) in tableData"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.id">
                    </el-option>
               </el-select>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitregisterdata">保存</el-button>
              <el-button @click="eddbankshow = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="新增"
        :visible.sync="addbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="名称" prop="name">
              <el-input v-model.trim="form.name" placeholder="请输入问题分类名称（系统后台显示问题分类名称）" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="排序值" prop="sort">
              <el-input v-model.number="form.sort" placeholder="请输入排序值（默认为0）" clearable required></el-input>
            </el-form-item>
            <el-form-item label="父级" prop="pid">
              <el-select style="width:200px;"  v-model="form.pid" placeholder="请选择一级分类">
                <el-option
                      label="一级分类"
                      value="0">
                    </el-option>
                 <el-option
                      v-for="(ot,i) in tableData"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.id">
                    </el-option>
               </el-select>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitForm">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  data(){
    return {
      form: {
        name: '',
        sort: '',
        pid: '',
      },
      registerdata:{
        name: '',
        sort: '',
        pid: '',
      },
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      addbankshow:false,
      eddbankshow:false,
      tableData: [],
      payChannel:[],//支付渠道
      formInline: {
         page:1,//页码 0不分页
         search_data:'',//检索内容
      },
      status:'',//支付渠道
      timevalue:'',//交易日期
      // 选中数组
      ghs: [],
      nums:[],
     //选中的记录数量
     initdata:{
         page:1,//页码 0不分页
         search_data:'',//检索内容
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
      bianji(id){
        let _this = this;
        let data = {
          id:id
        }
        axios.get(config.question_type_detail,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.registerdata = response.data.data;
              _this.eddbankshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      dd(id){
        let _this = this;
        this.$confirm('此操作将删除列, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.question_type_set_status,{id:id,status:-1})
          .then(response => {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.onSubmit('CHA');
            }else{
                _this.$message.error(response.data.message);
              }
          })
          .catch(error => {
            _this.$message.error(error);
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
       
      },
      submitForm() {// 手动编写非空验证代码
        let _this = this;
        if (!this.form.name || !this.form.sort || !this.form.pid) {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            axios.put(config.question_type,_this.form)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.addbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      submitregisterdata(){
        let _this = this;
        if (this.registerdata.name === '' || this.registerdata.sort === '' || this.registerdata.pid === '') {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        let data = {
          id:_this.registerdata.id,
          name:_this.registerdata.name,
          sort:_this.registerdata.sort,
          pid:_this.registerdata.pid,
        };
        this.$refs.registerdata.validate(valid => {
          if (valid) {
            axios.post(config.question_type,data)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.eddbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });

      },
      resetForm() {
        this.$refs.form.resetFields();
      },
      add(){
        this.addbankshow = true;
      },
      exportlist(){

      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        this.formInline.type = this.status == '' ? 1 : this.status;
        axios.get(config.question_type,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        if(this.selectedNum != 1){
          this.$message.error('不支持批量');
          return;
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.question_type_set_status, {id:this.ghs[0],status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.$emit('getrefresh')
            _this.init();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.question_type,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
    display: flex;
}
</style>